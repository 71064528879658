<template>
	<div id="ContentDetails">
		<!-- 内容详情 -->
		<!-- <webSearth /> -->
		<div class="banner">
			<el-image class="background" :src="banner" fit='cover'></el-image>
			<b class="mask"></b>
			<div class="main">
				<div class="cont">
					<p>新闻中心</p>
					<b>项目周期：2023年3月-2023年12月</b>
					<b>主办单位：北京生命绿洲公益服务中心</b>
					<b>鸣谢支持：<img :src="require('@/assets/imgs/QL_logo.png')" alt=""></b>
				</div>
			</div>
		</div>
		<div class="DetailsTit">
			<p>{{details.title}}</p>
			<span>文：{{details.author}} {{ $moment(details.updateTime).format('YYYY/MM/DD') }}</span>
		</div>
		<div class="cont" v-html="details.introduction"></div>
	</div>
</template>

<script>
	import {
		articleNews, // 动态、新闻详情
		getConfig
	} from '../../request/api'
	// import webSearth from '@/components/webSearth'
	export default {
		name: 'ContentDetails',
		components: {
			// webSearth
		},
		data() {
			return {
				details: '',
				banner: ''
			}
		},
		created() {
			this.getarticleNews();
			this.getConfigFun();
		},
		mounted() {},
		methods: {
			getarticleNews() {
				let data = this.$route.query.id
				articleNews(data).then(res => {
					console.log(res)
					if (res.code == 200) {
						this.details = res.data;
					}
				})
			},


			// 首页获取logo banner 流程图
			getConfigFun() {
				getConfig().then(res => {
					if (res.code == 200) {
						this.banner = res.data.bannerPath;
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#ContentDetails {
		display: flex;
		flex-direction: column;

		.banner {
			min-height: 60vh;
			position: relative;

			.webHander {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				z-index: 1;
			}

			.background {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 0;
			}

			.mask {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 0;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(252, 253, 255, 1) 100%)
			}

			.main {
				position: absolute;
				width: 1200px;
				top: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding-bottom: 150px;
				

				.cont {
					display: flex;
					flex-direction: column;
					margin: 0;

					p {
						font-size: 70px;
						font-weight: 600;
						line-height: 1.5;
					}

					b {
						font-size: 16px;
						font-weight: normal;
						margin-top: 12px;
						color: #000;
						opacity: .6;
						line-height: 1.5;

						img {
							height: 36px;
						}
					}
				}
			}
		}

		.DetailsTit {
			width: 1200px;
			margin: auto;
			display: flex;
			flex-direction: column;
			padding: 60px 0 10px;
			border-bottom: 2px solid rgba(229, 229, 229, 1);
			margin-bottom: 40px;

			p {
				font-size: 36px;
				color: rgba(31, 32, 34, 1);
				text-align: center;
			}

			span {
				font-size: 12px;
				color: rgba(115, 115, 115, 1);
				text-align: center;
				margin: 20px auto;
			}
		}

		.cont {
			width: 1200px;
			margin: auto;
		}
	}
</style>